<template>
  <div class="BrandListing layout-container mb-32 desk:mb-56">
    <div
      v-if="brandCategories.length"
      class="border-b border-light  py-24 desk:py-32"
    >
      <div class="type-headline-xl">
        {{ brandCategoryFilter === '' ? $t('brandlist.allCategories') : brandCategoryFilter }}
      </div>
      <div class="mt-12 flex flex-wrap gap-8">
        <button
          class="btn btn--secondary"
          :class="{
            selected: brandCategoryFilter === ''
          }"
          @click="brandCategoryFilter = ''"
        >
          {{ $t('brandlist.allCategories') }}
        </button>
        <button
          v-for="cat in brandCategories"
          :key="cat"
          class="btn btn--secondary"
          :class="{
            selected: brandCategoryFilter === cat
          }"
          @click="brandCategoryFilter = cat"
        >
          {{ cat }}
        </button>
      </div>
    </div>
    <div
      v-for="(value, key, index) in brands"
      :key="index"
      class="flex w-full items-start border-b border-light py-24 desk:py-32"
    >
      <div
        class="type-headline-2xl desk:type-headline-4xl basis-1/4 desk:basis-3/12"
      >
        {{ key }}
      </div>
      <div

        class="basis-3/4 desk:basis-9/12"
      >
        <div
          v-for="(thisBrand, index2) in value"
          :key="index2"
          class="flex items-center mb-18 last:m-0"
        >
          <AddRemoveFavoriteBrand
            :brand-id="thisBrand.brandCode"
            design="minimal"
            class="mr-8"
          />
          <nuxt-link
            :to="$manualUrl(thisBrand.brandLink)"
            class="block border-light "
          >
            {{ thisBrand.brandName }}
          </nuxt-link>
          <div
            class="inline-block bg-brightGreen text-darkest
                    pb-4 pt-6 px-8 ml-8
                    type-headline-xxs leading-single whitespace-nowrap
                    empty:hidden
                    "
          >
            {{ thisBrand.brandFlag }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BrandItem, useGlobalContentStore } from '~/store/globalContent';
import AddRemoveFavoriteBrand from '~/components/product/AddRemoveFavoriteBrand.vue';
const globalContent = useGlobalContentStore();

type BrandList = {
  [key: string]: BrandItem[]
}

await useAsyncData('load-brands',
  async() => {
    return await globalContent.loadBrands();
  }
);

const brandCategoryFilter = ref('');

const brandCategories = computed(()=> {
  return globalContent.brandList
    .filter((f)=> f.showInAutomaticList && f.brandCategory !== '')
    .map((m) => m.brandCategory.toLowerCase())
    .sort((a, b) => a.localeCompare(b, 'sv'))
    .filter((v,i,a) => a.indexOf(v) === i); // remove duplicates
});

const usedBrands = computed(()=> {
  const output = globalContent.brandList.filter((f)=> f.showInAutomaticList);
  if (brandCategoryFilter.value === '') {
    return output;
  }
  return output.filter((f) => f.brandCategory.toLowerCase() === brandCategoryFilter.value);
});

const brands = computed<BrandList>(() => {
  const letterSplit = {} as BrandList;
  const regex = /[0-9]/g;
  usedBrands.value.forEach((i) => {
    let firstLetter = i.brandName.charAt(0);
    if (firstLetter.match(regex)) {
      firstLetter = '0 - 9';
    }
    if (!letterSplit[firstLetter]) {
      letterSplit[firstLetter] = [];
    }
    letterSplit[firstLetter].push(i);
  });

  return letterSplit;
});
</script>

<style scoped lang="postcss">
</style>
