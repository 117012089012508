<template>
  <div class="BrandListingPage relative">
    <div v-editable="blok" class="w-full overflow-hidden pb-56 desk:pb-64">
      <div class="topMarginReference" />
      <AutoBreadcrumbs v-if="!blok.hideBreadcrumbs" />
      <!-- content above -->
      <SpaceHandler
        v-for="(childBlok, index) in blok.body"
        :key="childBlok._uid"
        :blok="childBlok"
      >
        <ProductList
          v-if="childBlok.component === 'ProductList'"
          :blok="childBlok"
          :placement="index"
        />
        <StoryblokComponent
          v-else
          :blok="childBlok"
          :placement="index"
        />
      </SpaceHandler>

      <!-- * * * * * * * * actual brand list -->
      <client-only>
        <div v-if="userStore.isLoggedIn">
          <div class="layout-container type-headline-2xl desk:type-headline-3xl mb-12">
            {{ $t('brandlist.favorites.headline') }}
          </div>
          <FavoriteBrandButtons class="mb-24 desk:mb-32 desk:layout-container" />
          <div class="layout-container">
            <div class=" border-b border-light" />
          </div>
        </div>
      </client-only>

      <BrandListing />

      <!-- content below -->
      <SpaceHandler
        v-for="(childBlok, index) in blok.bodyAfter"
        :key="childBlok._uid"
        :blok="childBlok"
        :placement="index"
      >
        <ProductList
          v-if="childBlok.component === 'ProductList'"
          :blok="childBlok"
          :placement="index"
        />
        <StoryblokComponent
          v-else
          :blok="childBlok"
          :placement="index"
        />
      </SpaceHandler>
    </div>
  </div>
</template>

<script setup lang="ts">
import { StoryBlokPropBase, StoryBlokSeoPlugin } from '~/constants/types/storyblok';
import { SbBlokData } from '@storyblok/vue';
import SpaceHandler from '~/components/body/SpaceHandler.vue';
import ProductList from '~/components/product/ProductList.vue';
import BrandListing from '~/components/brands/BrandListing.vue';
import useSeoCreator from '~/composeables/useSeoCreator';
import { useUserStore } from '~/store/user';
import FavoriteBrandButtons from '~/components/brands/FavoriteBrandButtons.vue';
import AutoBreadcrumbs from '~/components/body/AutoBreadcrumbs.vue';
const userStore = useUserStore();

type Props = StoryBlokPropBase & {
  body: SbBlokData[];
  bodyAfter: SbBlokData[];
  hideBreadcrumbs: boolean;
  seo: StoryBlokSeoPlugin;
}

const props = defineProps<{
  blok: Props,
}>();

if (props.blok.seo) {
  useSeoCreator().createMeta(props.blok.seo);
}

</script>

<style lang="postcss">

</style>
